import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import TW from "../assets/tw.png";
import JP from "../assets/jp.png";
import CN from "../assets/cn.png";
import ML from "../assets/ml.png";
const Location = (props) => {
    const { t } = useTranslation();
	let locations = [
		{
			img:TW,
			country:"location-tw",
			address:"location-tw-address",
			phone:"886-4-2292-2999"
		},
		{
			img:JP,
			country:"location-jp",
			address:"location-jp-address",
			phone:"06-6265-3024"
		},
		{
			img:CN,
			country:"location-cn",
			address:"location-cn-address",
			phone:"86-4008-863-880"
		},
		{
			img:ML,
			country:"location-ml",
			address:"location-ml-address",
			phone:"+603-5621 0213"
		},
	]
    useEffect(() => {}, []);
    return (
        <div>
            <div className="flex flex-col justify-end items-center w-full bg-[#F5F1EC] sm:p-8">
                <div className="about-banner-bg w-full"></div>
            </div>
            <div className="flex flex-col justify-start w-full pl-4 pt-8">
                <div className="mt-8 border-b text-left text-3xl font-bold text-[#555046] pb-8">
                    <span className="page-title">{t("menu-location")}</span>
                </div>
                <div className="text-left pt-4 text-lg text-[#555046] font-bold">
                    <span className="border-b-2 p-2 border-[#f67703]">
                        {t("location-asia")}
                    </span>
                </div>
                <div className=" mt-4 mb-16 flex justify-between flex-wrap w-full">
                    {locations.map((item) => (
						<div key={item.phone} className="text-left border mb-2 w-[90vw] border-[black] border-dotted p-4 box-border sm:w-[48vw]">
							<div className="flex">
								<img className="mr-3 mb-2" src={item.img} alt={item.country} />
								{t(item.country)}
							</div>
							<p className="mb-2">
								{t(item.address)}
							</p>
							<p className="mb-2">{t(item.phone)}</p>
						</div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Location;
