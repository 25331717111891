import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";

const Research = (props) => {
    const { t, i18n } = useTranslation();

    useEffect(() => {}, []);
    return (
        <div>
            <div className="flex flex-col justify-end items-center w-full bg-[#F5F1EC] sm:p-8">
                <div className="about-banner-bg w-full"></div>
            </div>
            <div className="flex flex-col justify-start w-full pl-4 pt-8">
                <div className="mt-8 border-b text-left text-3xl font-bold text-[#555046] pb-8">
                    <span className="page-title">{t("menu-research")}</span>
                </div>
                <div className="text-left pt-4">
                    <p className="mt-3">{t("research-desc")}</p>
                    <p className="text-lg my-4 font-bold text-[#F05F23]">{t("research-title")}</p>
                    <p className="">{t("research-desc2")}</p>
                    
                </div>
                <div className="text-left pt-8 mb-12">
                    <p className="text-xl font-bold text-[#F05F23] mb-4">{t("research-title2")}</p>
                    <p className="text-lg font-bold text-[#F05F23]"><span className="text-black mr-2">•</span>{t("research-sub-title1")}</p>
                    <p className="my-1 text-sm ml-4">{t("research-sub-desc1")}</p>
                    <p className="text-lg font-bold text-[#F05F23]"><span className="text-black mr-2">•</span>{t("research-sub-title2")}</p>
                    <p className="my-1 text-sm ml-4">{t("research-sub-desc2")}</p>
                    <p className="text-lg font-bold text-[#F05F23]"><span className="text-black mr-2">•</span>{t("research-sub-title3")}</p>
                    <p className="my-1 text-sm ml-4">{t("research-sub-desc3")}</p>
                </div>
            </div>
        </div>
    );
};

export default Research;
