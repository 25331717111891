import Index from "../pages/index"
import Research from "../pages/research"
import Vision from "../pages/vision"
import Location from "../pages/location"
const RouterList = [{
    path: "",
    components: Index,
}, {
    path: "research",
    components: Research,
}, {
    path: "vision",
    components: Vision,
}, {
    path: "location",
    components: Location,
}, ]

export default RouterList