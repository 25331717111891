import React, {useState,useEffect} from "react";
import { useTranslation } from "react-i18next";

import Menu from "../component/menu";
const Layout = (props) => {
    const { t, i18n } = useTranslation();
    const year = new Date().getFullYear();
    const [currentLang, setCurrentLang] = useState(
        window.localStorage.getItem("lang")
    );
    useEffect(() => {
        i18n.changeLanguage(currentLang);
    }, []);
    return (
        <div className="min-h-screen flex flex-col">
            <Menu {...props} />
            <article className="flex-1 mt-[64px]">
                {props.children}
            </article>
            <footer className="flex flex-col bg-[#F5F1EB] text-center p-2 text-xs  w-full">
                <p>
                    【Customer service】<span className="text-[#F05F23]">awsopteam@leading-global-solution.com</span><br/>
                    <span className="text-[#aaaaaa]">Copyright 2022 Guangzhou Angbao Trading Co., Ltd. All rights reserved.</span>
                </p>

            </footer>
        </div>
    );
};

export default Layout;
