import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Index = (props) => {
    const { t, i18n } = useTranslation();
    return (
        <div>
            <div className="pt-12 index-sm-bg sm:index-banner-bg flex flex-col justify-end items-center w-full">
                <div
                    className="text-white text-6xl font-black mb-8"
                    style={{
                        textShadow:
                            "0px 0px 20px #000, 0px 0px 20px #000, 0px 0px 20px #000",
                    }}
                >
                    {t("index-banner")}
                </div>
                <div className="bg-white bg-opacity-[0.9] p-14 font-bold text-[16px]">
                    {t("index-desc")}
                </div>
            </div>
            <div className="index-sm-bg-2 pt-12 sm:index-banner-bg-2 flex flex-col justify-end items-center w-full">
                <div
                    className="text-white text-6xl font-black mb-8"
                    style={{
                        textShadow:
                            "0px 0px 20px #000, 0px 0px 20px #000, 0px 0px 20px #000",
                    }}
                >
                    {t("index-banner2")}
                </div>
                <div className="bg-white bg-opacity-[0.9] p-14 font-bold text-[16px]">
                    {t("index-desc2")}
                </div>
            </div>
            <div className="flex justify-start">
                <Link
                    key="download"
                    className="px-3 py-2 ease-in duration-300 text-[#aaaaaa] text-sm"
                    to="/download/sport_vd004_202208191116_3.90.0_qat.apk"
                    target="_blank"
                    download
                >
                    {t("menu-download")}
                </Link>
            </div>
        </div>
    );
};

export default Index;
