import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";

const Vision = (props) => {
    const { t, i18n } = useTranslation();

    useEffect(() => {}, []);
    return (
        <div>
            <div className="flex flex-col justify-end items-center w-full bg-[#F5F1EC] sm:p-8">
                <div className="product-banner-bg w-full"></div>
            </div>
            <div className="flex flex-col justify-start w-full pl-4 pt-8">
                <div className="mt-8 border-b text-left text-3xl font-bold text-[#555046] pb-8">
                    <span className="page-title">{t("menu-vision")}</span>
                </div>
                <div className="text-left pt-4">
                    <p className="text-lg font-bold text-[#F05F23]">{t("vision-desc-title")}</p>
                    <p className="mt-3">{t("vision-desc")}</p>
                    <p className="mt-3">{t("vision-desc1-2")}</p>
                    
                </div>
                <div className="text-left pt-8 mb-12">
                    <p className="text-lg font-bold text-[#F05F23]">{t("vision-desc-title2")}</p>
                    <p className="mt-3">{t("vision-desc2")}</p>
                </div>
            </div>
        </div>
    );
};

export default Vision;
